<template>
  <div id="sixEcharts" style="width: 50%; height: 450px;">
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "waterPH",
  props: {
    landPHList: {
      type: Array, // 数据类型根据实际情况进行调整
      required: true,
    },
  },
  watch: {
    landPHList: {
      //immediate: true, // 立即执行一次
      handler(newValue) {
        if (newValue) {
          // 执行相应的操作，如数据渲染
          this.option.series[0].data=newValue
          this.getData()
        }
      },
    },
  },
  data() {
    return {
      option:{
        grid:{
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: "time",
          // min: `${new Date(new Date().setHours(0, 0, 0, 0)).getFullYear()}-${String(new Date(new Date().setHours(0, 0, 0, 0)).getMonth() + 1).padStart(2, '0')}-${String(new Date(new Date().setHours(0, 0, 0, 0)).getDate()).padStart(2, '0')} ${String(new Date(new Date().setHours(0, 0, 0, 0)).getHours()).padStart(2, '0')}:${String(new Date(new Date().setHours(0, 0, 0, 0)).getMinutes()).padStart(2, '0')}:${String(new Date(new Date().setHours(0, 0, 0, 0)).getSeconds()).padStart(2, '0')}`,
          // max: `${new Date(new Date().setHours(23, 59, 59, 999)).getFullYear()}-${String(new Date(new Date().setHours(23, 59, 59, 999)).getMonth() + 1).padStart(2, '0')}-${String(new Date(new Date().setHours(23, 59, 59, 999)).getDate()).padStart(2, '0')} ${String(new Date(new Date().setHours(23, 59, 59, 999)).getHours()).padStart(2, '0')}:${String(new Date(new Date().setHours(23, 59, 59, 999)).getMinutes()).padStart(2, '0')}:${String(new Date(new Date().setHours(23, 59, 59, 999)).getSeconds()).padStart(2, '0')}`,
        },
        yAxis: {
          type: 'value',
          name: "水肥PH值",
          axisLabel: {
            //在y轴的数据的值旁边拼接单位
            formatter: "{value} PH",
          },
          axisTick: {},
          min:0,
          max:200
        },
        series: [
          {
            max:1000,
            min:0,
            minInterval: 0,
            name: "当前PH值",
            data: [],
            type: 'line',
            itemStyle : {
              color:{
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#35BDC6' // 0% 处的颜色
                }, {
                  offset: 1, color: '#35BDC6' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            lineStyle:{
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#35BDC6' // 0% 处的颜色
                },{
                  offset: 0.5, color: '#35BDC6' // 0% 处的颜色
                }, {
                  offset: 1, color: '#35BDC6' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            areaStyle: {//区域填充渐变颜色
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(52,184,195,0.39)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(255, 255, 255, 0.01)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            }
          }
        ]
      },
    }
  },
  mounted() {
    // this.getData()
  },
  methods:{
    getData(){
      let chartDom = document.getElementById("sixEcharts");
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
      window.addEventListener("resize", () => {
        if (myChart) {
          myChart.resize();
        }
      });
      this.option && myChart.setOption(this.option);
    },
  }
}
</script>

<style scoped lang="scss">
#sixEcharts{
  border: 1px solid #000000;
  margin-top: 10px;
}
</style>
